import Layout from "../components/layout"
import {graphql} from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import PageTransition from 'gatsby-v2-plugin-page-transitions'
import "animate.css/animate.css";
import BodyClassName from 'react-body-classname';

export default class ArticlesPage extends React.Component {
  render() {
    const { data } = this.props
    const { edges: articles } = data.allMarkdownRemark

    return (
      <Layout>
      <PageTransition>
      <BodyClassName className="posts">
      <article className="postList" style={{"padding":"20px"}}>

      <div className="articleList">
      {articles
        .map(({ node: article }) => (
          <Link
            to={article.fields.slug}
            key={article.id}
            className="grow"
          >
          <div className="postcard" >
            <div className="cardthumb">
              <img alt="" src={article.frontmatter.thumb.childImageSharp.fluid.src}  />
            </div>
            <div className="cardtitle">
            <h3 className="no-margin">
                {article.frontmatter.title}
            </h3>

            <p className="opacity-half description line-clamp-3">
               {article.frontmatter.description}
            </p>
            </div>

          </div>
          </Link>
        ))}
        </div>
      </article>
      </BodyClassName>
      </PageTransition>
      </Layout>
    )
  }
}


ArticlesPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const ArticleQuery = graphql`
  query ArticlesQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date, frontmatter___title]},
      filter: { frontmatter: { templateKey: { eq: "blog-post" }, published: {ne: false} }},
    ) {
      edges {
        node {
          excerpt(pruneLength: 100)
          timeToRead
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            description
            published
            date(formatString: "MMMM DD, YYYY")
            thumb{
              childImageSharp{
                fluid(maxWidth: 500){
                  ...GatsbyImageSharpFluid
                }
              }
            }
            path
          }
        }
      }
    }
  }
`
